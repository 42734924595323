import store from "@/store/store";
import { HttpRestService } from "./http/HttpRestService";
export default class PedidosManutencaoService {
  vm;
  status;
  loading = false;
  dataAgendamento = "";

  setDataAgendamento(data) {
    this.dataAgendamento = data;
  }

  constructor() {}

  static build() {
    return new PedidosManutencaoService();
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  async requestPedidosManutencao(params) {
    try {
      const unidadeId = store.state.menu.selectedFilial.value;
      params.unidade_id = unidadeId;
      return await HttpRestService.get("/api/v2/pedido-web/manutencao", params);
    } catch (e) {
      return { status: 500, data: { list: [] } };
    }
  }

  async requestPedidosManutencaoExpress(params) {
    try {
      const unidadeId = store.state.menu.selectedFilial.value;
      params.unidade_id = unidadeId;
      return await HttpRestService.get("/api/v2/pedido-web/manutencao", params);
    } catch (e) {
      return { status: 500, data: { list: [] } };
    }
  }

  async getPedidos(page, filtro, pedidosAgil, pedidosWeb) {
    if (!this.validarDataFiltro(filtro.dataInicio, filtro.dataFinal)) return;
    this.loading = true;
    this.vm.pedidos = [];

    const body = {
      numero_pedido: filtro.numeroPedido ?? "",
      data_inicio: filtro.dataInicio ?? "",
      data_fim: filtro.dataFinal ?? "",
      transportadora: filtro.transportadoraNome ?? "",
      page: page,
      perPage: filtro.perPage ?? 10,
      express: pedidosAgil,
      fixador: pedidosAgil,
      web: pedidosWeb,
      ploomes: pedidosWeb,
    };
    this.vm.pagination = {};
    try {
      const response = pedidosAgil
        ? await this.requestPedidosManutencaoExpress(body)
        : await this.requestPedidosManutencao(body);

      if (response.status !== 200) {
        this.vm.modalAlert = {
          message: response.data.message,
          variant: "danger",
        };
        this.vm.$bvModal.show("modal-alert");
        this.loading = false;
        return false;
      }
      const { data } = response.data;
      const { pagination, list } = data;
      this.vm.pedidos = this.mapPedido(list);
      this.setTotalRegistros(pagination.total);
      this.vm.pagination = pagination;
      this.loading = false;
    } catch (e) {
      this.loading = false;
    }
  }

  setTotalRegistros(total) {
    if (this.vm.totalRegistros === 0) this.vm.totalRegistros = total;
  }

  validarDataFiltro(dataInicio, dataFinal) {
    if (dataInicio > dataFinal) {
      this.vm.modalAlert = {
        message: "A data inicial não poser maior que a data final.",
        variant: "danger",
      };
      this.vm.$bvModal.show("modal-alert");
      return false;
    }
    return true;
  }

  mapPedido(pedidos) {
    return pedidos.map((value) => {
      return {
        numeroPedido: value.numeroPedido,
        dataProducao: value.dataProducao,
        clienteNome: value.clienteNome,
        transportadoraNome: value.transportadoraNome,
        kitDescricao: value.kitDescricao,
        agendado: false,
        bloqueioStatus: value.bloqueioStatus,
      };
    });
  }

  agendarManutencao() {
    if (this.vm.agendados.length) {
      this.vm.$bvModal.show("modal-agendar");
    } else {
      this.vm.modalAlert = {
        message: "Selecione pelo menos um pedido para agendar.",
        variant: "danger",
      };
      this.vm.$bvModal.show("modal-alert");
    }
  }
  agendarVariosManutencao() {
    this.vm.$bvModal.show("modal-agendar-varios-manutencao");
  }

  selectAll() {
    this.vm.pedidos.map((value) => {
      return {
        ...value,
        agendado: false,
      };
    });
    if (
      this.vm.pedidos.every((item) => {
        return item.agendado === true;
      })
    ) {
      this.vm.pedidos.forEach((pedido) => {
        pedido.agendado = false;
      });
    } else {
      this.vm.pedidos.forEach((pedido) => {
        pedido.agendado = true;
      });
    }
  }

  validarDataAgendamento() {
    const regexDataHora = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    const regexData = /^\d{4}-\d{2}-\d{2}/;
    if (regexDataHora.test(this.dataAgendamento) === false) {
      this.vm.modalAlert = {
        message: `Selecione a ${
          regexData.test(this.dataAgendamento) ? "hora" : "data"
        }.`,
        variant: "danger",
      };
      this.vm.$bvModal.show("modal-alert");
      return;
    }

    const today = new Date().toISOString();
    const currDate = today.match(/\d{4}-\d{2}-\d{2}/)[0];
    const agendamentoDate = this.dataAgendamento.match(/\d{4}-\d{2}-\d{2}/)[0];
    if (agendamentoDate < currDate) {
      this.vm.modalAlert = {
        message: "A data não pode ser inferior à de hoje.",
        variant: "danger",
      };
      this.vm.$bvModal.show("modal-alert");
      return false;
    }

    if (agendamentoDate === currDate) {
      const currTime = today.match(/\d{2}:\d{2}:\d{2}/)[0];
      const agendamentoTime =
        this.dataAgendamento.match(/\d{2}:\d{2}:\d{2}/)[0];
      if (agendamentoTime < currTime) {
        this.vm.modalAlert = {
          message: "A hora não pode ser inferior à de agora.",
          variant: "danger",
        };
        this.vm.$bvModal.show("modal-alert");
        return false;
      }
    }
    return true;
  }

  async sendAgendamento() {
    if (!this.validarDataAgendamento()) return;

    const body = {
      listaPedidos: this.getPedidosSelecionados(),
      dataProducao: this.dataAgendamento,
    };

    this.vm.pagination = {};
    this.loading = true;
    this.vm.$bvModal.hide("modal-agendar");

    await HttpRestService.post(
      "/api/v2/pedido-web/agendamento-manutencao",
      body
    ).then(async (response) => {
      if (response.status === 200) {
        this.vm.modalAlert = {
          message: "Pedidos agendados com sucesso.",
          variant: "success",
        };
        this.vm.$bvModal.show("modal-alert");
        await this.getPedidos(1, {});
      }
    });
  }

  async sendAgendamentoVarios(orders) {
    if (orders === "") {
      this.vm.modalAlert = {
        message: "O campo de pedidos está vazio.",
        variant: "danger",
      };
      this.vm.$bvModal.show("modal-alert");
      return false;
    }

    if (!this.validarDataAgendamento()) return;
    this.setPedidos(orders);

    if (this.vm.pedidosInadequados.length > 0) {
      this.vm.pedidosInadequados = this.vm.pedidosInadequados.map((pedido) => {
        return {
          pedido,
          _rowVariant: "danger",
        };
      });
      this.vm.$bvModal.show("modal-alert-pedidos-inadequados");
      await this.getPedidos(1, {});
      return;
    }

    const body = {
      listaPedidos: this.vm.pedidos,
      dataProducao: this.dataAgendamento,
    };

    this.vm.pagination = {};
    this.loading = true;
    this.vm.$bvModal.hide("modal-agendar-varios-manutencao");

    await HttpRestService.post(
      "/api/v2/pedido-web/agendamento-manutencao",
      body
    ).then(async (response) => {
      if (response.data.statusCode === 200) {
        if (response.data.data === true) {
          this.vm.modalAlert = {
            message: "Pedidos agendados com sucesso.",
            variant: "success",
          };
          this.vm.$bvModal.show("modal-alert");
          this.vm.pedidosNaoReagendados = [];
          await this.getPedidos(1, {});
        } else {
          let pedidosInexistentes = response.data.data.pedidosInexistentes;
          if (pedidosInexistentes) {
            pedidosInexistentes = pedidosInexistentes.map((pedido) => {
              return {
                pedido,
                motivo: "Pedido inexistente",
                _rowVariant: "danger",
              };
            });
          }
          let pedidosNaoReagendaveis = response.data.data.pedidosNaoAgendados;
          if (pedidosNaoReagendaveis) {
            pedidosNaoReagendaveis = pedidosNaoReagendaveis.map((pedido) => {
              return {
                pedido,
                motivo: "Pedido não reagendável",
                _rowVariant: "warning",
              };
            });
          }
          this.vm.pedidosNaoReagendados = [
            ...pedidosInexistentes,
            ...pedidosNaoReagendaveis,
          ];
        }
        if (this.vm.pedidosNaoReagendados.length > 0) {
          this.vm.$bvModal.show("modal-alert-pedidos-nao-reagendados");
          await this.getPedidos(1, {});
          return;
        }
      } else if (response.data.statusCode === 400) {
        this.vm.modalAlert = {
          message: "Erro ao agendar os pedidos.",
          variant: "danger",
        };
        this.vm.$bvModal.show("modal-alert");
        await this.getPedidos(1, {});
      } else if (response.data.statusCode === 500) {
        this.vm.modalAlert = {
          message: "Erro interno do servidor.",
          variant: "danger",
        };
        this.vm.$bvModal.show("modal-alert");
        await this.getPedidos(1, {});
      } else {
        await this.getPedidos(1, {});
      }
    });
  }

  getPedidosSelecionados() {
    return this.vm.agendados.map((value) => {
      return value.numeroPedido;
    });
  }

  setPedidos(string, separators = /[\s,]+/) {
    let parts = string.split(separators).filter((part) => part.trim() !== "");

    let adequateOrders = [];
    let inadequateOrders = [];
    for (let i = 0; i < parts.length; i++) {
      let part = parts[i].trim();
      let parsedPart = parseInt(part);

      if (
        isNaN(parsedPart) ||
        parsedPart.toString() !== part ||
        parsedPart < 0
      ) {
        if (inadequateOrders.indexOf(part) === -1) {
          inadequateOrders.push(part);
        }
      } else {
        if (adequateOrders.indexOf(parsedPart) === -1) {
          adequateOrders.push(parsedPart);
        }
      }
    }
    this.vm.pedidos = adequateOrders;
    this.vm.ordersLength = this.vm.pedidos.length;
    this.vm.pedidosInadequados = inadequateOrders;
  }
}
